import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { LocalizedDatePipe } from 'angular-v2-pipes';
import { clamp, isNil, round, toNumber } from 'lodash';

@Component({
  selector: 'ui-stay-timeline',
  standalone: true,
  imports: [CommonModule, TranslateModule, LocalizedDatePipe],
  templateUrl: './stay-timeline.component.html',
  styleUrls: ['./stay-timeline.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StayTimelineComponent {
  @Input() checkIn?: Date | string | undefined;
  @Input() checkInLabel?: string;
  @Input() checkInFrom?: string;
  @Input() checkInTo?: string;
  @Input() checkOut?: Date | string | undefined;
  @Input() checkOutLabel?: string;
  @Input() checkOutTo?: string;
  @Input() checkOutUntil?: string;
  @Input() guestArea = false;

  get checkInText(): string {
    return this.checkInLabel || (this.guestArea ? `GA.CHECK_IN` : `SH.CHECK_IN`);
  }

  get checkOutText(): string {
    return this.checkOutLabel || (this.guestArea ? `GA.CHECK_OUT` : `SH.CHECK_OUT`);
  }

  get progress(): number {
    if (isNil(this.checkIn) || isNil(this.checkOut)) {
      return 0;
    }

    const checkInHour = this.#extractHour(this.checkInFrom);
    const checkOutHour = this.#extractHour(this.checkOutTo);

    const startDate = new Date(this.checkIn).setHours(checkInHour);
    const endDate = new Date(this.checkOut).setHours(checkOutHour);
    const todayDate = Date.now();

    const progressPercentage = round(((todayDate - startDate) / (endDate - startDate)) * 100);

    return clamp(progressPercentage, 0, 100);
  }

  #extractHour(time?: string): number {
    if (!time) {
      return 0;
    }
    const [hour] = time.split(':');
    return toNumber(hour) || 0;
  }
}

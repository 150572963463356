import { Injectable, signal } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  boot,
  hide,
  Intercom,
  onHide,
  onShow,
  onUnreadCountChange,
  show,
  shutdown,
  update,
} from '@intercom/messenger-js-sdk';
import { UserArgs } from '@intercom/messenger-js-sdk/types';
import { IAccount, ICompanyRole, IVisitorsArea } from '@smarttypes/core';
import { getUnixTime, parseISO } from 'date-fns';
import { forkJoin, Subscription, switchMap, take } from 'rxjs';
import { filter } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { VisitorsConfiguratorService } from '../../modules/visitors-configurator/visitors-configurator.service';
import { AccountService } from './account.service';
import { AuthService } from './auth.service';
import { CompanyService } from './company.service';

@Injectable({
  providedIn: 'root',
})
export class IntercomService {
  #opened = signal<boolean>(false);
  #counter = signal<number>(0);
  #intercomOn = false;
  #sub?: Subscription;

  constructor(
    private readonly authService: AuthService,
    private readonly companyService: CompanyService,
    private readonly accountService: AccountService,
    private readonly visitorsConfService: VisitorsConfiguratorService,
    private readonly router: Router,
  ) {
    if (environment?.intercomAppId) {
      Intercom({
        app_id: environment.intercomAppId as string,
      });
    }
  }

  get getCounter(): number {
    return this.#counter();
  }

  shutDown() {
    this.shutdownIntercom();
    this.#sub?.unsubscribe();
    this.#sub = undefined;
  }

  bootIntercom() {
    this.#sub = new Subscription();
    this.#sub.add(
      this.authService.$loggedIn
        .pipe(
          filter(e => e),
          filter(() => !!environment?.intercomAppId),
          switchMap(() =>
            forkJoin([
              this.accountService.$currentUser().pipe(take(1)),
              this.companyService.$currentUser().pipe(take(1)),
              this.visitorsConfService.getInitialConfig().pipe(take(1)),
            ]),
          ),
        )
        .subscribe(([user, company, ga]) => {
          // const isSH = user?.email?.includes('@mysmarthotel.com') ?? false;
          console.log('Init intercom');
          if (company.role !== 'service' /* && !isSH*/) {
            if (!user) {
              this.shutdownIntercom();
            } else {
              this.initIntercom(user, company, ga);
              this.#initEvents();
            }
          }
        }),
    );

    this.#sub.add(
      this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
        this.update();
      }),
    );
  }

  update(data?: UserArgs) {
    if (this.authService.loggedIn && this.#intercomOn) {
      update(data || {});
    }
  }

  toggle() {
    if (this.#opened()) {
      hide();
    } else {
      show();
    }
  }

  private shutdownIntercom() {
    if (this.#intercomOn) {
      this.#intercomOn = false;
      shutdown();
    }
  }

  private initIntercom(user: IAccount, company: ICompanyRole, ga: IVisitorsArea) {
    if (!environment?.intercomAppId || this.#intercomOn) {
      return;
    }
    let name = [user?.person?.firstName, user?.person?.lastName].filter(c => !!c).join(' ');

    if (name.trim() === '') {
      name = user.userName as string;
    }

    if (!name || name.trim() === '') {
      name = user._id as string;
    }

    this.#intercomOn = true;
    boot({
      app_id: environment.intercomAppId,
      environment: environment.intercomEnvironment,
      hide_default_launcher: true,
      email: user.email,
      user_id: user._id as string,
      created_at: getUnixTime(parseISO(user.createdAt as unknown as string)),
      appVersion: environment.appVersion,
      name,
      phone: user.phone,
      language: user.language ?? 'en',
      role: company.role,
      account_status: company.status,
      website: ga.url,
      company: {
        id: company._id as string,
        name: company.name,
        plan: company.plan,
        industry: company.type,
        timezone: company.timezone,
        created_at: getUnixTime(parseISO(company.createdAt as unknown as string)),
        account_status: company.status,
        website: ga.url,
      },
    });
  }

  #initEvents() {
    onHide(() => this.#opened.set(false));
    onShow(() => this.#opened.set(true));
    onUnreadCountChange((unreadCount: number) => {
      this.#counter.set(unreadCount);
    });
  }
}

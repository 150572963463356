<ui-inline-toast
  *ngIf="errors?.length && (!isCompleted || isFailed)"
  [labels]="errors"
  [toastInput]="toastTypes.warning"
  icon="icons-warning"
></ui-inline-toast>

<div [class.three-columns]="updatedSuccess && (removedSuccess || importedFailed)" class="summary">
  <div>
    <label>{{ 'SH.VISITORS_IMPORT.IMPORTED' | translate }}</label>
    <span [class.success]="importedSuccess" class="value">{{ importedSuccess }}</span>
  </div>
  <div *ngIf="updatedSuccess">
    <label>{{ 'SH.VISITORS_IMPORT.UPDATED' | translate }}</label>
    <span class="value success">{{ updatedSuccess }}</span>
  </div>
  <div *ngIf="importedFailed">
    <label>{{ 'SH.VISITORS_IMPORT.FAILED' | translate }}</label>
    <span class="value">{{ importedFailed }}</span>
  </div>
  <div *ngIf="removedSuccess">
    <label>{{ 'SH.VISITORS_IMPORT.REMOVED' | translate }}</label>
    <span class="value danger">{{ removedSuccess }}</span>
  </div>
  <div *ngIf="addedRooms">
    <label>{{ 'SH.VISITORS_IMPORT.ADDED_ROOMS' | translate }}</label>
    <span class="value success">{{ addedRooms }}</span>
  </div>
</div>

<ui-button-rectangle
  (click)="onCloseClick()"
  label="{{ 'SH.CLOSE' | translate }}"
  size="l"
  type="secondary"
></ui-button-rectangle>

import { Component, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { DragNDropDirective } from 'angular-v2-directives';
import { MaxCharsPipe } from 'angular-v2-pipes';

@Component({
  selector: 'ui-import-uploader',
  templateUrl: './import-uploader.component.html',
  styleUrls: ['./import-uploader.component.scss'],
  standalone: true,
  imports: [DragNDropDirective, SvgIconComponent, TranslateModule, MaxCharsPipe],
})
export class ImportUploaderComponent {
  @Input() file?: File;
  @Input() formats = '';
  @Input() pending = false;
  @ViewChild('fileDropRef', { static: false }) fileDropRef?: ElementRef;
  @ViewChild('uploader', { static: false }) uploader?: ElementRef;
  @Output() fileAdded: EventEmitter<File> = new EventEmitter();
  @Output() fileRemoved: EventEmitter<File> = new EventEmitter();

  constructor(private renderer2: Renderer2) {}

  onFileDragOver() {
    this.renderer2.addClass(this.uploader?.nativeElement, 'drag-over');
  }

  onFileDragLeave() {
    this.renderer2.removeClass(this.uploader?.nativeElement, 'drag-over');
  }

  onFileDragDrop() {
    this.renderer2.removeClass(this.uploader?.nativeElement, 'drag-over');
  }

  onFileDropped(file: File[]) {
    this.fileAdded.emit(file[0]);
    this.file = file[0];
  }

  fileBrowseHandler(file: File[]) {
    this.fileAdded.emit(file[0]);
    this.file = file[0];
  }

  onFileRemove() {
    if (!this.pending) {
      this.file = undefined;
      this.fileRemoved.emit();
    }
  }
}

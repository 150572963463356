<ng-container *ngIf="disabled">
  <ui-inline-toast
    [toastInput]="toastTypes.warning"
    icon="icons-warning-copy-4"
    label="{{ 'SH.VISITORS_IMPORT_APARTMENT.IMPORT_APARTMENT_TIP' | translate }}"
  ></ui-inline-toast>
  <div (click)="onDownloadTemplateClick()" class="template-bar">
    <div>
      <svg-icon class="excel-icon" src="/assets/icons/icons-color-excel.svg"></svg-icon>
      <span>{{ 'SH.VISITORS_IMPORT.DOWNLOAD_TEMPLATE' | translate }}</span>
    </div>
    <svg-icon class="file-ico" src="/assets/icons/icons-download2.svg"></svg-icon>
  </div>
</ng-container>
<ui-import-uploader
  (fileAdded)="onFileAdded($event)"
  (fileRemoved)="onFileRemoved()"
  [file]="file"
  [formats]="formats"
  [pending]="uploadInProgress"
></ui-import-uploader>

<ui-button-rectangle
  (click)="onImportClick()"
  [disabled]="disabled || uploadInProgress"
  [pending]="uploadInProgress"
  label="{{ (!file ? 'SH.VISITORS_IMPORT.UPLOAD' : 'SH.VISITORS_IMPORT.IMPORT') | translate }}"
  size="l"
  type="primary"
></ui-button-rectangle>

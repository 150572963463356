<div>
  @if (showLogo) {
    <sh-logo-desktop *shIsBreakpoint="'desktop'"></sh-logo-desktop>
  }
  <sh-hamburger *shIsBreakpoint="'mobile'"></sh-hamburger>
  @if (showLogo) {
    <sh-logo-mobile *shIsBreakpoint="'mobile'"></sh-logo-mobile>
  }
</div>
<div class="d-flex align-items-center">
  @if (intercomEnable) {
    <button (click)="toggleIntercom()">
      <svg-icon src="/assets/icons/icons-intercom.svg"></svg-icon>
      @if (intercomCounter) {
        <span class="counter">{{ intercomCounter }}</span>
      }
    </button>
  }
  @if (showAccount) {
    <sh-user></sh-user>
  }
</div>
<sh-sidebar-backdrop *shIsBreakpoint="'mobile'"></sh-sidebar-backdrop>

<ui-modal-base
  (dismiss)="onCloseClick()"
  [confirmModal]="false"
  [header]="title | translate"
  [padding]="false"
  subtitle="{{ 'SH.VISITORS_IMPORT.FROM' | translate }} {{ fileName ? fileName : ('SH.VISITORS_IMPORT.FORMATS' | translate) }}"
>
  <div content>
    <sh-visitors-import-apartment-default
      (fileAdded)="onFileAdded($event)"
      (fileRemoved)="onFileRemoved()"
      (importClick)="onImportClick()"
      *ngIf="!isImportedFinished"
      [file]="file"
      [importType]="importType"
      [uploadInProgress]="uploadInProgress"
    ></sh-visitors-import-apartment-default>
    <sh-visitors-import-apartment-finish
      (closeClick)="onCloseClick()"
      *ngIf="isImportedFinished && isImportedSuccess && !isStatusAway"
      [addedRooms]="addedRooms"
      [importedFailed]="importedFailed"
      [importedSuccess]="importedSuccess"
      [isCompleted]="isStatusCompleted"
      [isFailed]="isStatusFailed"
      [removedSuccess]="removedSuccess"
      [response]="response"
      [updatedSuccess]="updatedSuccess"
    ></sh-visitors-import-apartment-finish>
    <sh-visitors-import-apartment-errors
      (fileAdded)="onFileAdded($event)"
      (fileRemoved)="onFileRemoved()"
      (importClick)="onImportClick()"
      *ngIf="!isImportedSuccess && isImportedFinished && !isStatusAway"
      [file]="file"
      [response]="response"
      [uploadInProgress]="uploadInProgress"
    ></sh-visitors-import-apartment-errors>
    <sh-visitors-import-apartment-warnings
      (fileAdded)="onFileAdded($event)"
      (fileRemoved)="onFileRemoved()"
      (importClick)="onImportClick($event)"
      *ngIf="isImportedFinished && isStatusAway"
      [file]="file"
      [response]="response"
      [uploadInProgress]="uploadInProgress"
    ></sh-visitors-import-apartment-warnings>
  </div>
</ui-modal-base>

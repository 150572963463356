import { Component, Input } from '@angular/core';
import { CDN_DEFAULT_OPTIONS, convertUrlToCdnCgi, ICdnOptions } from 'angular-v2-utils';

@Component({
  selector: 'ui-widget-cover-image',
  templateUrl: './widget-cover-image.component.html',
  styleUrls: ['./widget-cover-image.component.scss'],
  standalone: true,
})
export class WidgetCoverImageComponent {
  @Input() imagePath?: string;
  @Input() imageBucket?: string;
  @Input() imageUrl?: string;
  @Input() placeholderPath = '';
  @Input() cdnOptions: ICdnOptions = CDN_DEFAULT_OPTIONS;

  get hasImage(): boolean {
    return (!!this.imagePath && !!this.imageBucket) || !!this.imageUrl;
  }

  get baseImagePath(): string {
    return convertUrlToCdnCgi(this.imageBucket, this.imagePath, this.imageUrl);
  }

  get coverBackground1x(): string {
    return this.getOptimizedSize('1');
  }

  get coverBackground2x(): string {
    return this.getOptimizedSize('2');
  }

  get coverBackground3x(): string {
    return this.getOptimizedSize('3');
  }

  private getOptimizedSize(size: string) {
    if (this.cdnOptions.dpr) {
      return this.baseImagePath.replace(this.cdnOptions.dpr.toString(), size);
    }

    return this.baseImagePath;
  }
}

<ul [class]="size" class="tabs">
  @for (tab of tabs; track tab.key) {
    @if (!tab.hideWhenZero || count(tab) > 0 || activeTab === tab.key || tab?.persist) {
      <li
        #htmlliElement
        (click)="onTabClick(tab)"
        [attr.data-key]="tab.key"
        [class.active]="tab.key === activeTab"
        [class.disabled]="tab.key !== activeTab && loading"
      >
        <p>{{ tab.label | translate }} @if (count(tab) > 0) {
          <span class="counter">{{ count(tab) }}</span>
        }</p>
      </li>
    }
  }
</ul>
@if (showIndicator) {
  <div class="slider">
    <div [style.left.px]="leftPosition" [style.width.px]="slideWidth"></div>
  </div>
}

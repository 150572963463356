import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ICompanyRole } from '@smarttypes/core';
import { ConfirmModalComponent } from '@ui/common/modals';
import { get } from 'lodash';
import { BsModalService } from 'ngx-bootstrap/modal';
import { interval, Subscription, tap } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { CompanyService } from './company.service';
import { NotificationBarService, NotificationIdEnum } from './notification-bar.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentRequiredService {
  private company?: ICompanyRole;
  private paymentRequiredModal = false;
  private paymentRequiredSub: Subscription = new Subscription();

  constructor(
    private readonly companyService: CompanyService,
    private readonly modalService: BsModalService,
    private notificationBarService: NotificationBarService,
    private translateService: TranslateService,
  ) {
    this.companyService
      .$currentUser()
      .pipe(
        filter(c => !!c),
        tap(company => (this.company = company)),
        filter(() => this.paymentMissing),
        tap(() =>
          this.notificationBarService.addNotification({
            id: NotificationIdEnum.PaymentRequired,
            header: this.translateService.instant('SH.PAYMENT_REQUIRED'),
            description: this.translateService.instant('SH.PAYMENT_REQUIRED.DESCRIPTION'),
            icon: 'icons-exclamation-filled',
            type: 'negative',
          }),
        ),
        filter(() => get(environment, 'ui.paymentRequiredModal', true)),
        switchMap(() => interval(120000)), // 2min
        filter(() => !this.paymentRequiredModal),
      )
      .subscribe(() => {
        this.openPaymentRequiredModal();
      });
  }

  get paymentMissing(): boolean {
    return get(this.company, 'isMissingPayment', false);
  }

  unsubscribe() {
    this.paymentRequiredSub.unsubscribe();
  }

  private openPaymentRequiredModal() {
    this.paymentRequiredModal = true;
    this.modalService
      .show(ConfirmModalComponent, {
        class: 'modal-dialog-default',
        initialState: {
          text: {
            header: this.translateService.instant('SH.PAYMENT_REQUIRED'),
            body: this.translateService.instant('SH.PAYMENT_REQUIRED.MODAL_DESCRIPTION'),
            cancel: this.translateService.instant('SH.CLOSE'),
            blockButtons: true,
            btnCancelClass: 'secondary',
          },
        },
      })
      ?.onHide?.subscribe(() => {
        this.paymentRequiredModal = false;
      });
  }
}

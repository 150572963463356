import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SvgIconComponent } from 'angular-svg-icon';

@Component({
  selector: 'ui-dropdown-item',
  standalone: true,
  imports: [CommonModule, SvgIconComponent, TranslateModule],
  templateUrl: './dropdown-item.component.html',
  styleUrl: './dropdown-item.component.scss',
})
export class DropdownItemComponent {
  name = input.required<string>();
  icon = input<string>();
}

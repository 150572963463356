<div [class.account-expired]="accountExpired" [class.notification-bar]="showNotificationBar">
  <sh-notification-bar #notificationBar *ngIf="showNotificationBar"></sh-notification-bar>
  <sh-top-bar #topBar *ngIf="showTopBar" [style.top.px]="topOffset"></sh-top-bar>
  @if (showTopNavigation && !showTopBar) {
    <sh-top-navigation #topNavigation [items]="menuStandaloneTop" [style.top.px]="topOffset"></sh-top-navigation>
  }
  <sh-sidebar (mouseenter)="toggleSidebar(false)" (mouseleave)="toggleSidebar(true)" *ngIf="showSideBar"
              [style.top.px]="topOffset">
    <sh-menu-group *ngIf="menuStandaloneOperations.length > 0" [items]="menuStandaloneOperations"></sh-menu-group>
    <sh-menu-group *ngIf="menuStandaloneResources.length > 0" [items]="menuStandaloneResources"></sh-menu-group>
  </sh-sidebar>
  <router-outlet class="dashboard-outlet"></router-outlet>
</div>
<sh-onboarding></sh-onboarding>

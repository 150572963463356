import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';

@Injectable()
export class SwitchToCompanyInterceptor implements HttpInterceptor {
  readonly authService = inject(AuthService);

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          const switchToCompanyId = event.headers.get('Switch-To-Company');
          if (switchToCompanyId) {
            this.authService.switch(switchToCompanyId).subscribe(() => {
              window.location.reload();
            });
          }
        }
      }),
    );
  }
}

@if (loaded && editorConfig) {
  <div [class.textarea-wrapper]="placeholders">
    @if (templateTop && !readonly) {
      <p>{{ templateTop }}</p>
    }
    <quill-editor
      (drop)="false"
      (onContentChanged)="textChanged($event)"
      (onEditorCreated)="editorInit($event)"
      [class.readonly]="readonly"
      [formControl]="control"
      [formats]="allowedFormats"
      [modules]="editorConfig"
      [placeholder]="placeholder"
    ></quill-editor>
    @if (templateBottom && !readonly) {
      <p>{{ templateBottom }}</p>
    }
  </div>
}

@if (showPagination()) {
  <div class="pagination-per-page">
    <p>{{ 'SH.ROWS_PER_PAGE' | translate }}</p>
    <ui-select
      [items]="itemsPerPage"
      [clearable]="false"
      [searchable]="false"
      [formControl]="itemsPerPageControl"
      dropdownPosition="top"
      type="ng-select-secondary-small ng-select-100"
    ></ui-select>
  </div>
  <pagination (pageChanged)="pageChanged($event)" [(ngModel)]="currentPage" (numPages)="totalPages.set($event)"
              [customNextTemplate]="nextTemplate"
              [customPageTemplate]="pageTemplate"
              [customLastTemplate]="lastTemplate"
              [customFirstTemplate]="firstTemplate"
              [customPreviousTemplate]="prevTemplate" [itemsPerPage]="itemsPerPageControl.value ?? 10"
              [directionLinks]="true"
              [boundaryLinks]="true"
              [maxSize]="5"
              [class.last-page-button]="canShowLastPage"
              [class.first-page-button]="canShowFirstPage"
              [totalItems]="total()">
  </pagination>

  <ng-template #pageTemplate let-currentPage="currentPage" let-disabled="disabled" let-page>
    <ui-button-rectangle [active]="page.active" [centerText]="true" [label]="page.number"
                         class="page" type="tertiary"></ui-button-rectangle>
  </ng-template>

  <ng-template #nextTemplate let-currentPage="currentPage" let-disabled="disabled">
    @if (disabled) {
      <ui-button-square icon="icons-arrow-short-right-s" [disabled]="true"></ui-button-square>
    } @else {
      <ui-button-rectangle [iconSmall]="true" iconRight="icons-arrow-short-right-m" label="Next"
                           type="secondary"></ui-button-rectangle>
    }
  </ng-template>

  <ng-template #prevTemplate let-currentPage="currentPage" let-disabled="disabled">
    <ui-button-square [disabled]="disabled" icon="icons-arrow-left-short-s"></ui-button-square>
  </ng-template>

  <ng-template #lastTemplate let-currentPage="currentPage" let-disabled="disabled">
    <ui-button-rectangle [centerText]="true" label="{{ totalPages() }}"
                         class="page" type="tertiary"></ui-button-rectangle>
  </ng-template>

  <ng-template #firstTemplate let-currentPage="currentPage" let-disabled="disabled">
    <ui-button-rectangle [centerText]="true" label="1"
                         class="page" type="tertiary"></ui-button-rectangle>
  </ng-template>
}

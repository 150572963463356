<div class="box">
  @if (room) {
    @if (roomImage) {
      <img [alt]="room.roomNo" [src]="roomImage">
    } @else {
      <ui-room-badge [roomNumber]="room.roomNo"></ui-room-badge>
    }
  }
  <div>
    @if (room) {
      <h6>{{ room.roomNo }}</h6>
    }
    <p>{{ 'GA.RESERVATION' | translate }} {{ guest.reservation?.reservationId }}</p>
  </div>
</div>
@if (alreadySigned) {
  <ui-button-rectangle
    [forceFullWidth]="true"
    [routerLink]="['/']"
    class="return"
    icon="icons-arrow-left-short-s"
    label="{{ 'GA.CHECK_IN.BACK_TO_RESERVATION' | translate }}"
    size="m"
    type="secondary"
  ></ui-button-rectangle>
}

import {
  AngularSignaturePadModule,
  NgSignaturePadOptions,
  SignaturePadComponent,
} from '@almothafar/angular-signature-pad';
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ApiValidatorService, WindowService } from '@smarthotel/angular-services';
import { ICountry, IFileItem, ILanguage, Timezones } from '@smarttypes/core';
import { IGuest } from '@smarttypes/hotel';
import {
  IAnswer,
  IConsent,
  IConsentAnswer,
  IQuestion,
  IRegistrationAttachment,
  IRegistrationCardConfig,
  QuestionTypeEnum,
} from '@smarttypes/registration-card';
import { ButtonRectangleComponent } from '@ui/common/buttons';
import { CheckboxComponent, ErrorMessageComponent, FormComponent } from '@ui/common/forms';
import { AddRoommateComponent, FileUploaderComponent, StayTimelineComponent } from '@ui/features';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { COUNTRIES, LANGUAGES, SelectOption, untilDestroyed } from 'angular-v2-utils';
import { formatInTimeZone } from 'date-fns-tz';
import { get, set, unset } from 'lodash';
import moment from 'moment';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

import { CheckinFormQuestionComponent } from './checkin-form-question/checkin-form-question.component';
import { CheckinFormRoomBoxComponent } from './checkin-form-room-box/checkin-form-room-box.component';
import { CheckinSignatureModalComponent } from './checkin-signature-modal/checkin-signature-modal.component';

// @TODO move all models to separate file
export interface FormQuestion {
  required?: boolean;
  order?: number;
  title?: string;
  type?: string;
}

export interface RegistrationCardFillData {
  signature?: string;
  answers: IAnswer[];
  consentAnswers: IConsentAnswer[];
  configurationCard: any; // @TODO types
  registrationAttachments?: IFileItem[];
}

interface Translations {
  lang: string;
  translation: Record<string, string>;
}

interface ISavePayload {
  form: FormGroup;
  roommates: FormArray;
  questionAnswers: IAnswer[];
  consentAnswers: IConsentAnswer[];
}

interface IFileAddedItem {
  files: File[];
  index: number;
  formGroup: FormGroup;
}

@Component({
  selector: 'lib-checkin-form',
  templateUrl: './checkin-form.component.html',
  styleUrls: ['./checkin-form.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    StayTimelineComponent,
    FormComponent,
    CheckinFormQuestionComponent,
    ReactiveFormsModule,
    ErrorMessageComponent,
    CheckboxComponent,
    AddRoommateComponent,
    AngularSvgIconModule,
    AngularSignaturePadModule,
    ButtonRectangleComponent,
    ModalModule,
    FileUploaderComponent,
    CheckinFormRoomBoxComponent,
    RouterLink,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckinFormComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @Input() guest?: IGuest;
  @Input() disableSignatureAndConsentsEdit = false;
  @Input() preview = false;
  @Input() submitLabel = '';
  @Input() surveyMode = false;
  @Input() roomBox = true;
  @Input() fillData?: RegistrationCardFillData;
  @Input() translations: Translations[] = [];
  @Input() showRoommates = false;
  @Input() translatePrefix: 'GA' | 'SH' = 'GA';
  @Input() rooms: SelectOption[] = [];
  @Input() roomLoading = false;
  @Input() pending = false;
  @Input() registrationCardConfig?: IRegistrationCardConfig;
  @ViewChild('signature') signaturePad?: SignaturePadComponent;
  @ViewChildren('question') questionsComponents?: QueryList<CheckinFormQuestionComponent>;
  @Output() roommateChange = new EventEmitter<FormArray>();
  @Output() loadMoreRooms = new EventEmitter<{
    term: string;
    initial: boolean;
  }>();
  @Output() save: EventEmitter<ISavePayload> = new EventEmitter<ISavePayload>();
  @Output() fileAdded: EventEmitter<IFileAddedItem> = new EventEmitter<IFileAddedItem>();
  @Output() fileRemove: EventEmitter<File> = new EventEmitter<File>();
  languages: ILanguage[] = LANGUAGES;
  countries: ICountry[] = COUNTRIES;
  form?: FormGroup;
  questionType = QuestionTypeEnum;
  signaturePadOptions: NgSignaturePadOptions = {
    minWidth: 5,
    canvasWidth: 452,
    canvasHeight: 100,
    backgroundColor: '#f2f2f2',
    penColor: '#18cff7',
  };
  roommateFormArray?: FormArray = new FormArray<any>([]);
  showSignaturePadInfo = true;
  isSigned = false;
  pendingUploadFiles: File[] = [];

  constructor(
    private readonly apiValidator: ApiValidatorService,
    private readonly modalService: BsModalService,
    private readonly cdr: ChangeDetectorRef,
    private readonly windowService: WindowService,
    private readonly translateService: TranslateService,
    private readonly router: Router,
    private readonly toastService: ToastrService,
  ) {}

  get questionControls(): any {
    return (this.form?.get('questions') as FormGroup)?.controls;
  }

  get consentControls(): any {
    return (this.form?.get('consents') as FormGroup)?.controls;
  }

  get attachmentControls(): any {
    return (this.form?.get('registrationAttachments') as FormGroup)?.controls;
  }

  get isFirstnameAndLastname(): boolean {
    return (
      this.hasQuestionType(QuestionTypeEnum.collectFirstName) && this.hasQuestionType(QuestionTypeEnum.collectLastName)
    );
  }

  get isCheckInAndCheckOut(): boolean {
    return this.hasQuestionType(QuestionTypeEnum.checkIn) && this.hasQuestionType(QuestionTypeEnum.checkOut);
  }

  get isDigitalSignatureEnabled(): boolean {
    return !!this.registrationCardConfig?.digitalSignature;
  }

  get showSignatureClearButton(): boolean {
    return (
      this.windowService.innerWidth > 768 &&
      !this.preview &&
      this.form?.get('digitalSignature')?.value?.length > 0 &&
      !this.disableSignatureAndConsentsEdit
    );
  }

  get signatureTranslation(): string {
    const translation = this.translations?.find(
      item => item.lang === this.registrationCardConfig?.language,
    )?.translation;
    return translation
      ? this.translateService.instant(translation[`${this.translatePrefix}.CHECK_IN-FORM.GUEST_SIGNATURE_HERE`] || '')
      : this.translateWithPrefix('CHECK_IN-FORM.GUEST_SIGNATURE_HERE');
  }

  get checkIn(): string {
    return this.getFormArrayAnswer(QuestionTypeEnum.checkIn);
  }

  get checkOut(): string {
    return this.getFormArrayAnswer(QuestionTypeEnum.checkOut);
  }

  get alreadySigned(): boolean {
    return !!this.guest?.reservation?.actions?.registrationCardSignedAt;
  }

  get submitText(): string {
    if (this.submitLabel) {
      return this.translateWithPrefix(this.submitLabel);
    }
    if (this.surveyMode) {
      return this.translateWithPrefix('CHECK_IN.SEND_FORM');
    }
    if (this.alreadySigned) {
      return this.translateWithPrefix('CHECK_IN.RE_SIGN_CARD');
    }
    return this.translateWithPrefix('CHECK_IN.SIGN_CARD');
  }

  get backText(): string {
    return this.translateWithPrefix('BACK_TO_VISITORS_AREA');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['registrationCardConfig']?.currentValue) {
      this.prepareForm();
    }
  }

  ngOnInit() {
    if (this.preview) {
      this.translatePrefix = 'SH';
    }
  }

  ngOnDestroy() {
    console.log();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.fillData?.signature) {
        this.signaturePad?.fromDataURL(this.fillData.signature);
        this.cdr.detectChanges();
      }
    }, 150);
  }

  onSaveClick() {
    if (this.pendingUploadFiles.length > 0) {
      this.toastService.error(this.translateService.instant('GA.FILE_UPLOAD_IN_PROGRESS'));
      return;
    }

    if (this.pending || !this.apiValidator.formIsValid(this.form as FormGroup)) {
      return;
    }

    const getFormArrayValue = (key: string) => (this.form?.get(key) as FormArray)?.value;

    const mapAnswer = (item: any) => {
      switch (item.type) {
        case QuestionTypeEnum.apartmentNumber:
          return item.answer?._id
            ? {
                _id: item.answer._id,
                roomType: item.answer.roomType,
                roomNo: item.answer.roomNo,
              }
            : { roomNo: item.answer };
        case QuestionTypeEnum.checkIn:
          return this.guest?.reservation?.from ?? this.convertDateToUtc(item.answer);
        case QuestionTypeEnum.checkOut:
          return this.guest?.reservation?.to ?? this.convertDateToUtc(item.answer);
        case QuestionTypeEnum.date:
          return this.convertDateToUtc(item.answer);
        default:
          return item.answer;
      }
    };

    const mapQuestions = (questions: any[]) =>
      questions.map((item: any) => ({
        question: {
          questionType: item.type,
          isRequired: item.required,
          title: item.title,
          order: item.order,
        },
        answer: mapAnswer(item),
      }));

    const mapConsents = (consents: any[]) =>
      consents.map((item: any) => ({
        consent: {
          consentType: item.type,
          isRequired: item.required,
          title: item.title,
          order: item.order,
        },
        answer: item.answer || false,
      }));

    this.save.emit({
      form: this.form as FormGroup,
      questionAnswers: mapQuestions(getFormArrayValue('questions')),
      consentAnswers: mapConsents(getFormArrayValue('consents')),
      roommates: this.roommateFormArray as FormArray,
    });
  }

  onSignatureClick() {
    if (this.preview || this.windowService.innerWidth > 768) {
      return;
    }

    this.modalService
      .show(CheckinSignatureModalComponent, {
        initialState: {
          formControl: this.form?.get('digitalSignature'),
          prefix: this.translatePrefix,
        },
        class: 'modal-default modal-checkin-signature',
      })
      ?.content?.action?.pipe(untilDestroyed(this))
      .subscribe((data: string) => {
        if (data) {
          this.form?.get('digitalSignature')?.patchValue(data);
          this.signaturePad?.fromDataURL(data);
        }
        this.isSigned = false;
        this.showSignaturePadInfo = false;
        this.cdr.detectChanges();
      });
  }

  clearSignature() {
    this.signaturePad?.clear();
    this.form?.get('digitalSignature')?.patchValue('');
  }

  onDrawEnd() {
    this.form?.get('digitalSignature')?.patchValue(this.signaturePad?.toDataURL());
  }

  translateWithPrefix(key: string): string {
    return this.translateService.instant(`${this.translatePrefix}.${key}`);
  }

  onFileAdd(files: File[], fileFormGroup: FormGroup, index: number): void {
    this.fileAdded.emit({ files, formGroup: fileFormGroup, index });
    this.pendingUploadFiles.push(...Object.values(files));
  }

  onFileRemove(file: File, fileFormGroup: FormGroup): void {
    const fileNames = (fileFormGroup?.get('name')?.value ?? []).filter((name: string) => name !== file.name);
    fileFormGroup?.get('name')?.setValue(fileNames, { emitEvent: false });
    this.fileRemove.emit(file);
  }

  filesHint(multiple: boolean): string {
    if (multiple) {
      return this.translateWithPrefix('CHECK_IN-FORM.MULTIPLE_FILES_HINT');
    }
    return '';
  }

  isCheckinOrCheckout(control: FormGroup): boolean {
    return control?.value?.type === QuestionTypeEnum.checkIn || control?.value?.type === QuestionTypeEnum.checkOut;
  }

  updatePendingFiles(file: File, fileFormGroup?: FormGroup) {
    const fileNames = fileFormGroup?.get('name')?.value ?? [];
    fileNames.push(file.name);
    fileFormGroup?.get('name')?.setValue(fileNames, { emitEvent: false });
    this.pendingUploadFiles = this.pendingUploadFiles.filter(f => f.name !== file.name);
    this.cdr.detectChanges();
  }

  onRoommateChange(event: FormArray) {
    this.roommateFormArray = event;
  }

  onLoadMoreRooms(event: { term: string; initial: boolean }) {
    this.loadMoreRooms.emit({ term: event.term, initial: event.initial });
  }

  shouldApplyCol6Class(control: AbstractControl): boolean {
    const valueType = control?.value?.type;

    const isFirstOrLastName =
      this.isFirstnameAndLastname &&
      (valueType === this.questionType.collectFirstName || valueType === this.questionType.collectLastName);

    const isCheckInOrCheckOut =
      this.isCheckInAndCheckOut &&
      (valueType === this.questionType.checkIn || valueType === this.questionType.checkOut);

    return isFirstOrLastName || isCheckInOrCheckOut;
  }

  private convertDateToUtc(date: string) {
    return new Date(formatInTimeZone(new Date(date), Timezones.Etc_UTC, 'yyyy-MM-dd')).toISOString();
  }

  private prepareForm() {
    this.form = new FormGroup(
      {
        questions: new FormArray([]),
        consents: new FormArray([]),
        title: new FormControl(this.registrationCardConfig?.title),
        digitalSignature: new FormControl(
          '',
          this.registrationCardConfig?.digitalSignature ? [Validators.required] : [],
        ),
        footer: new FormControl(this.registrationCardConfig?.footer),
        registrationAttachments: new FormArray([]),
      },
      {
        updateOn: 'change',
      },
    );

    this.pushQuestionsIntoForm('questions');
    this.pushQuestionsIntoForm('consents');
    this.pushFilesIntoForm();
    this.setDateListeners();

    if (this.preview) {
      this.form?.disable();
    }
  }

  private pushQuestionsIntoForm(type: string) {
    const questions = Array.from(get(this.registrationCardConfig, type) || []) as IQuestion[] | IConsent[];

    questions
      .sort((a: IQuestion | IConsent, b: IQuestion | IConsent) => a.order - b.order)
      .forEach((item: IQuestion | IConsent) => {
        const fieldType = get(item, type === 'questions' ? 'questionType' : 'consentType', '');
        const fieldOrder = (questions?.indexOf(item as IQuestion & IConsent) || 0) + 1;

        const formGroup = new FormGroup({
          type: new FormControl(fieldType),
          required: new FormControl(item.isRequired),
          title: new FormControl(item.title),
          order: new FormControl(fieldOrder),
          answer: new FormControl(null, this.#getFieldValidators(type, item.isRequired)),
        });

        if (fieldType.toLowerCase() === 'email') {
          formGroup.get('answer')?.addValidators(this.apiValidator.validateEmail as ValidatorFn);
        }

        (this.form?.get(type) as FormArray)?.push(formGroup, {
          emitEvent: false,
        });
      });
  }

  #getFieldValidators(type: string, isRequired: boolean): ValidatorFn[] {
    if (type === 'consents' && isRequired) {
      return [Validators.requiredTrue];
    }
    if (type === 'questions' && isRequired) {
      return [Validators.required];
    }
    return [];
  }

  private pushFilesIntoForm() {
    const files = Array.from(get(this.registrationCardConfig, 'registrationAttachments') || []);
    const formFiles = this.form?.get('registrationAttachments') as FormArray;
    files.forEach((item: IRegistrationAttachment) => {
      const formGroup = new FormGroup({
        title: new FormControl(item.title),
        multiple: new FormControl(item.multiple ?? false),
        required: new FormControl(item.isRequired),
        name: new FormControl(null, item.isRequired ? [Validators.required] : []),
      });

      formFiles.push(formGroup, {
        emitEvent: false,
      });
    });
  }

  private fillForm() {
    if (!this.fillData) {
      return;
    }

    if (this.registrationCardConfig) {
      if (this.fillData?.signature) {
        set(this.registrationCardConfig, 'digitalSignature', this.fillData?.signature);
        this.form?.get('digitalSignature')?.patchValue(this.fillData?.signature);
      }

      this.fillData.answers.forEach((answer: IAnswer, index: number) => {
        const control = (this.form?.get('questions') as FormArray)?.at(index);
        control?.get('answer')?.patchValue(answer.answer);
      });

      this.fillData.consentAnswers.forEach((answer: IConsentAnswer, index: number) => {
        const control = (this.form?.get('consents') as FormArray)?.at(index);
        control?.get('answer')?.patchValue(answer.answer);
      });
    }
  }

  private setDateListeners() {
    setTimeout(() => {
      if (
        this.form?.value?.questions?.some((item: any) => item.type === QuestionTypeEnum.checkIn) &&
        this.form?.value?.questions?.some((item: any) => item.type === QuestionTypeEnum.checkOut)
      ) {
        const indexCheckIn = this.form?.value?.questions?.findIndex(
          (item: any) => item.type === QuestionTypeEnum.checkIn,
        );
        const indexCheckOut = this.form?.value?.questions?.findIndex(
          (item: any) => item.type === QuestionTypeEnum.checkOut,
        );
        const questionCheckInComponent = this.questionsComponents?.find((item, i) => i === indexCheckOut);
        const questionCheckOutComponent = this.questionsComponents?.find((item, i) => i === indexCheckIn);
        const checkInControl = (this.form?.get('questions') as FormArray).at(indexCheckIn)?.get('answer');
        const checkOutControl = (this.form?.get('questions') as FormArray).at(indexCheckOut)?.get('answer');

        checkInControl?.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
          if (questionCheckInComponent) {
            if (checkInControl?.value) {
              set(
                questionCheckInComponent.bsConfig,
                'minDate',
                new Date(moment(checkInControl?.value).add(1, 'days').format('YYYY-MM-DD')),
              );
            } else {
              unset(questionCheckInComponent.bsConfig, 'minDate');
            }
          }
        });

        checkOutControl?.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
          if (questionCheckOutComponent) {
            if (checkOutControl?.value) {
              set(
                questionCheckOutComponent.bsConfig,
                'maxDate',
                new Date(moment(checkOutControl?.value).subtract(1, 'days').format('YYYY-MM-DD')),
              );
            } else {
              unset(questionCheckOutComponent.bsConfig, 'maxDate');
            }
          }
        });
      }

      this.fillForm();
      this.cdr.detectChanges();
    }, 150);
  }

  private getFormArrayAnswer(type: QuestionTypeEnum): string {
    return this.form?.get('questions')?.value?.find((item: FormQuestion) => item.type === type)?.answer || '';
  }

  private hasQuestionType(questionType: QuestionTypeEnum): boolean {
    return this.form?.value?.questions.some((item: FormQuestion) => item.type === questionType);
  }
}

import { CdkDrag, CdkDragMove, CdkDragRelease } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'ui-swipe-item',
  templateUrl: './swipe-item.component.html',
  styleUrls: ['./swipe-item.component.scss'],
  standalone: true,
  imports: [CommonModule, CdkDrag],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SwipeItemComponent implements AfterViewInit {
  @ViewChild('dragContainer', { static: false }) dragContainer!: ElementRef;
  @ViewChild('actionButtons', { static: false }) actionButtons!: ElementRef;
  @Input() isMobile = false;
  isDragging = false;
  isExpand = false;

  dragPosition = { x: 0, y: 0 };

  #maxLeftDrag = 0;
  #dragThreshold = 0;

  constructor(private cdr: ChangeDetectorRef) {}

  ngAfterViewInit() {
    if (this.isMobile) {
      this.#calculateDragLimits();
    }
    this.cdr.detectChanges();
  }

  onDragMoved(event: CdkDragMove) {
    if (!this.isMobile) return;

    this.isDragging = true;
    const currentX = event.source.getFreeDragPosition().x;

    if (currentX > 0) {
      this.dragPosition.x = 0;
    } else if (this.isExpand && currentX < this.#maxLeftDrag) {
      this.dragPosition.x = this.#maxLeftDrag;
    } else {
      this.dragPosition.x = currentX;
    }

    this.isExpand = this.dragPosition.x <= -this.#dragThreshold;
  }

  onDragStart() {
    this.#calculateDragLimits();
    this.cdr.detectChanges();
  }

  onDragReleased(event: CdkDragRelease) {
    if (!this.isMobile) return;
    this.isDragging = false;

    if (this.#maxLeftDrag === 0) {
      this.isExpand = false;
    }

    this.dragPosition.x = this.isExpand ? this.#maxLeftDrag : 0;

    setTimeout(() => {
      this.dragPosition = { ...this.dragPosition };
      this.cdr.detectChanges();
    });
    event.source.getRootElement().style.transform = `translateX(${this.dragPosition.x}px)`;
  }

  close() {
    setTimeout(() => {
      this.dragPosition.x = 0;
      this.isExpand = false;
      this.dragPosition = { ...this.dragPosition };
      this.#calculateDragLimits();
      this.cdr.detectChanges();
    }, 10);
  }

  #calculateDragLimits() {
    if (!this.actionButtons) {
      return;
    }

    const actionWidth = this.actionButtons.nativeElement.offsetWidth;
    this.#maxLeftDrag = -actionWidth;
    this.#dragThreshold = actionWidth / 2;
  }
}

import { CommonModule } from '@angular/common';
import { Component, HostListener, inject, input, ViewEncapsulation } from '@angular/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { copyToClipboard } from 'angular-v2-utils';

import { ToastService } from '../custom-toastr/toast.service';

@Component({
  selector: 'ui-copy-text-button',
  templateUrl: './copy-text-button.component.html',
  styleUrls: ['./copy-text-button.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  standalone: true,
  imports: [CommonModule, SvgIconComponent],
})
export class CopyTextButtonComponent {
  value = input.required<string>();
  toastService = inject(ToastService);

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    event.stopPropagation();
    copyToClipboard(this.value());
    this.toastService.info('SH.COPIED');
  }
}

@if (company) {
  <div dropdown class="btn-group" [isAnimated]="false" (onShown)="onDropdownShown()">
    <div class="companies-button" dropdownToggle [class.clickable]="companies.length > 1">
      <ui-avatar size="m" type="initialsUser" key="logo" [object]="company"></ui-avatar>
      <span class="company-name">{{ company.name }}</span>
      <div class="actions" *smartCoreHasOneModule="[appFunctionality.settings]">
        <ui-button-circle icon="icons-settings" size="m" type="tertiary-v2"
                          (click)="onSettings($event)"></ui-button-circle>
      </div>
      <svg-icon class="arrow" src="/assets/icons/icons-arrow-short-down-m.svg" *ngIf="companies.length > 1"></svg-icon>
    </div>
    @if (companies.length > 1) {
      <ul *dropdownMenu [@ngIfDropdownOpen] class="dropdown-menu dropdown-menu-inverted">
        <li class="filter">
          <input
            class="search-input"
            type="text"
            placeholder="{{ 'SH.SEARCH_COMPANY' | translate }}"
            autocomplete="off"
            (input)="onFilterChange($event)"
            (click)="onFilterInputClick($event)"
            #searchInput
          />
        </li>
        @if (filteredCompanies.length > 0) {
          <li class="company" *ngFor="let company of filteredCompanies">
            <a href="#" class="dropdown-item" (click)="changeCompany($event, company)">
              <ui-avatar size="s" type="initialsUser" key="logo" [object]="company"></ui-avatar>
              <span>{{ company.name }}</span>
            </a>
          </li>
        } @else {
          <li class="company not-found">
            <span>{{ 'SH.NO_RESULTS' | translate }}.</span>
          </li>
        }
      </ul>
    }
  </div>
}

import {
  AngularSignaturePadModule,
  NgSignaturePadOptions,
  SignaturePadComponent,
} from '@almothafar/angular-signature-pad';
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ButtonRectangleComponent } from '@ui/common/buttons';
import { WindowService } from 'angular-v2-services';
import { BsModalService } from 'ngx-bootstrap/modal';

export enum Orientation {
  portrait = 'portrait',
  landscape = 'landscape',
}

@Component({
  selector: 'lib-checkin-signature-modal',
  templateUrl: './checkin-signature-modal.component.html',
  styleUrls: ['./checkin-signature-modal.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AngularSignaturePadModule,
    ButtonRectangleComponent,
    TranslateModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckinSignatureModalComponent implements OnInit, AfterViewInit {
  @Input() formControl?: any;
  @Input() prefix = 'GA';
  @ViewChild('signature') public signaturePad?: SignaturePadComponent;
  @Output() action: EventEmitter<string> = new EventEmitter();
  signaturePadOptions?: NgSignaturePadOptions;
  showSign = false;
  sign?: string | boolean;

  constructor(
    private modalService: BsModalService,
    public cd: ChangeDetectorRef,
    private translateService: TranslateService,
    private windowService: WindowService,
  ) {}

  @HostBinding('class')
  get orientation(): Orientation {
    return window.screen.orientation?.type.startsWith('portrait') ? Orientation.portrait : Orientation.landscape;
  }

  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange() {
    this.signaturePad?.clear();
    this.sign = '';
    setTimeout(() => {
      this.setOrientation();
    }, 50);
  }

  ngOnInit() {
    setTimeout(() => {
      this.setSignaturePadOptions();
      this.setOrientation();
    }, 150);
  }

  ngAfterViewInit() {
    if (this.formControl?.value) {
      this.signaturePad?.fromDataURL(this.formControl?.value);
      this.sign = true;
      this.cd.detectChanges();
    }
  }

  setOrientation() {
    this.signaturePad?.off();
    this.showSign = false;
    this.cd.detectChanges();
    setTimeout(() => {
      this.showSign = true;
      this.cd.detectChanges();
      if (this.orientation === Orientation.portrait) {
        this.portraitCanvas();
      }
    }, 50);
  }

  portraitCanvas(): void {
    const canvas = this.signaturePad?.getCanvas();
    const ctx = canvas && canvas?.getContext('2d');
    if (ctx) {
      ctx.rotate(1.5 * Math.PI);
      ctx.translate(-canvas.height, 0);
    }
  }

  onDrawEnd(): void {
    this.sign = true;
    this.formControl?.patchValue(this.signaturePad?.toDataURL(), {
      emitEvent: false,
    });
  }

  onSubmit(): void {
    this.action.emit(this.formControl?.value);
    this.closeModal();
  }

  onCancel(): void {
    this.sign = '';
    this.closeModal();
  }

  onClear() {
    this.signaturePad?.clear();
    this.formControl?.patchValue('', { emitEvent: false });
    this.sign = '';
    if (this.orientation === Orientation.portrait) {
      this.setOrientation();
    }
  }

  translateWithPrefix(key: string): string {
    return this.translateService.instant(`${this.prefix}.${key}`);
  }

  private setSignaturePadOptions() {
    const innerWidth = this.windowService.innerWidth;
    const innerHeight = this.windowService.innerHeight;
    this.signaturePadOptions = {
      canvasWidth: (innerWidth > innerHeight ? innerWidth : innerHeight) - 80,
      canvasHeight: (innerHeight < innerWidth ? innerHeight : innerWidth) - 150,
      minWidth: 2,
      backgroundColor: '#f2f2f2', // $gray-200
      penColor: '#18cff7', // $blue-500
    };
  }

  private closeModal(): void {
    this.modalService.hide();
    setTimeout(() => {
      window.scrollTo(0, this.windowService?.document?.body?.scrollHeight ?? 0);
    }, 50);
  }
}

import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class WindowService {
  constructor(@Inject(DOCUMENT) private _doc: Document) {}

  get document(): Document | undefined {
    return this._doc.defaultView?.document;
  }

  get window(): Window | null {
    return this._doc.defaultView;
  }

  get innerHeight(): number {
    return this._doc.defaultView?.innerHeight || 0;
  }

  get innerWidth(): number {
    return this._doc.defaultView?.innerWidth || 0;
  }

  get location(): Location {
    return this._doc.location;
  }

  isAppleDevice(): boolean {
    const expression = /(Mac|iPhone|iPod|iPad)/i;

    return expression.test(navigator.platform);
  }
}

import { CommonModule } from '@angular/common';
import { Component, computed, inject, input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgxTippyModule, NgxTippyProps, NgxTippyService } from 'ngx-tippy-wrapper';

import { IDropdownItem } from './dropdown.interface';
import { DropdownItemComponent } from './dropdown-item/dropdown-item.component';

@Component({
  selector: 'ui-dropdown',
  standalone: true,
  imports: [CommonModule, AngularSvgIconModule, NgxTippyModule, TranslateModule, RouterLink, DropdownItemComponent],
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent {
  readonly tippyService = inject(NgxTippyService);
  readonly dropdownTippyConfig: NgxTippyProps = {
    arrow: true,
    theme: 'dropdown',
    maxWidth: '400px',
    placement: 'bottom-start',
    offset: [0, 5],
    allowHTML: true,
    interactive: true,
    interactiveBorder: 30,
    trigger: 'click',
    animation: 'scale',
    hideOnClick: true,
  };
  id = input.required<string>();
  items = input.required<IDropdownItem[]>();
  modalId = computed(() => {
    return `menu-${this.id()}`;
  });

  onItemClick(action?: () => void) {
    if (action) {
      action();
    }
    this.tippyService.hide(this.modalId());
  }
}

@if (isMobile) {
  <div class="swipe-wrapper">
    <div #actionButtons class="action-buttons" [class.active]="isExpand" [class.dragging]="isDragging">
      <ng-content select="[swipe-actions]"></ng-content>
    </div>

    <div
      #dragContainer
      (cdkDragMoved)="onDragMoved($event)"
      (cdkDragReleased)="onDragReleased($event)"
      (cdkDragStarted)="onDragStart()"
      [cdkDragFreeDragPosition]="dragPosition"
      cdkDrag
      cdkDragLockAxis="x"
      class="content"
      [class.expanded]="isExpand"
    >
      <ng-container *ngTemplateOutlet="item"></ng-container>
    </div>
  </div>
} @else {
  <ng-container *ngTemplateOutlet="item"></ng-container>
}

<ng-template #item>
  <ng-content select=".swipe-item"></ng-content>
</ng-template>

import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpFeature,
  HttpFeatureKind,
  provideHttpClient,
  withFetch,
  withInterceptors,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';

import { httpClientBaseUrlInterceptor } from './services/http-client-base-url.interceptor';
import { RetryTimeoutInterceptor } from './services/http-retry-timeout.interceptor';
import { SMART_ASSISTANT_CONFIG, SmartAssistantConfig } from './smart-assistant.config.interface';
import { SmartAssistantService } from './smart-assistant.service';

const SmartAssistantServiceFactory = (httpClient: HttpClient, config: SmartAssistantConfig) =>
  new SmartAssistantService(httpClient, config);

export function provideSmartAssistant(config: SmartAssistantConfig): EnvironmentProviders {
  const httpClientFeatures: HttpFeature<HttpFeatureKind>[] = [withFetch()];

  if (config.withInterceptorsFromDi) {
    httpClientFeatures.push(withInterceptorsFromDi());
  }

  return makeEnvironmentProviders([
    provideHttpClient(withInterceptors([httpClientBaseUrlInterceptor()]), ...httpClientFeatures),
    {
      provide: SMART_ASSISTANT_CONFIG,
      useValue: config,
    },
    {
      provide: SmartAssistantService,
      useFactory: SmartAssistantServiceFactory,
      deps: [HttpClient, SMART_ASSISTANT_CONFIG],
      multi: false,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RetryTimeoutInterceptor,
      multi: true,
    },
  ]);
}

<h2>{{ 'SH.VISITORS_PLACE.GET_DIRECT_ACCESS' | translate }}</h2>
<ui-inline-toast
  *ngIf="needPublish"
  [toastInput]="toastTypes.warning"
  icon="icons-warning-copy-4"
  label="{{ 'SH.VISITORS_PLACE.PUBLISH_TO_ACTIVATE_URL' | translate }}"
></ui-inline-toast>
<ui-form [readonly]="!canSave" label="{{ 'SH.VISITORS_PLACE.URL' | translate }}">
  <div class="wrapper">
    <div class="form-input form-input-prefix">{{ url }}/</div>
    <input
      [formControl]="permalinkControl"
      [readonly]="!canSave"
      class="form-input form-input-number"
      formInput
      placeholder="{{ 'SH.ADD_TITLE' | translate }}"
      type="text"
    />
    <div class="links">
      <ui-button-rectangle
        (click)="onSave()"
        *ngIf="canSave"
        [disabled]="inputDisabled"
        label="{{ 'SH.SAVE' | translate }}"
        size="s"
        type="primary"
      ></ui-button-rectangle>
      <ng-container *ngIf="!canSave">
        <ui-button-circle
          (click)="copyLink()"
          [iconSmall]="true"
          icon="icons-copy"
          type="quaternary"
        ></ui-button-circle>
        <ui-button-circle
          (click)="openLink()"
          [iconSmall]="true"
          icon="icons-external"
          type="quaternary"
        ></ui-button-circle>
      </ng-container>
    </div>
  </div>
  @if (!widgetId) {
    <small class="form-text text-muted"><strong class="important">{{ 'SH.IMPORTANT' | translate }}:</strong>
      {{ 'SH.VISITORS_PLACE.YOU_CAN_SAVE_ONCE' | translate }}</small
    >
  }
</ui-form>
<div
  (click)="onDownloadClick()"
  (mouseout)="hovering = false"
  (mouseover)="hovering = true"
  [class.disabled]="canSave || needPublish"
  class="qr-code"
>
  <svg-icon class="icon" src="/assets/icons/icons-qr.svg"></svg-icon>
  <p>{{ 'SH.VISITORS_PLACE.DOWNLOAD_QR_CODE' | translate }}</p>
  <ui-button-circle
    [hover]="hovering"
    [loading]="downloading"
    icon="icons-download-small"
    type="quaternary"
  ></ui-button-circle>
</div>

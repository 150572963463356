import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { LoaderComponent } from '@ui/common';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { Toast } from 'ngx-toastr';

@Component({
  selector: 'ui-custom-toastr',
  standalone: true,
  imports: [CommonModule, AngularSvgIconModule, LoaderComponent],
  templateUrl: './custom-toastr.component.html',
  styleUrls: ['./custom-toastr.component.scss'],
})
export class CustomToastrComponent extends Toast {
  @Input() icon?: string;
  @Input() pending = false;
  @Input() buttonText?: string;
  @Input() onButtonClick?: () => void;

  onClick() {
    if (this.onButtonClick) {
      this.onButtonClick();
    }
  }
}

import { CommonModule } from '@angular/common';
import { Component, computed, model, OnDestroy, OnInit, output } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { IPagination } from '@smarttypes/api';
import { ButtonRectangleComponent, ButtonSquareComponent } from '@ui/common/buttons';
import { SelectComponent } from '@ui/common/forms';
import { PageChangedEvent, PaginationModule } from 'ngx-bootstrap/pagination';
import { distinctUntilChanged, Subscription } from 'rxjs';

import { DEFAULT_PAGINATION, ITEMS_PER_PAGE } from './pagination.consts';

@Component({
  selector: 'ui-pagination',
  standalone: true,
  imports: [
    CommonModule,
    PaginationModule,
    ButtonRectangleComponent,
    FormsModule,
    ButtonSquareComponent,
    ReactiveFormsModule,
    SelectComponent,
    TranslateModule,
  ],
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  host: {
    '[class.hidden]': '!showPagination()',
  },
})
export class PaginationComponent implements OnInit, OnDestroy {
  total = model(0);
  pagination = model<IPagination>(DEFAULT_PAGINATION);
  showPagination = computed(() => this.total() > 10);
  paginationChange = output<IPagination>();
  currentPage = 1;
  itemsPerPageControl = new FormControl<number>(ITEMS_PER_PAGE[0] as number);
  readonly itemsPerPage = ITEMS_PER_PAGE as number[];
  totalPages = model(0);
  private subscriptions: Subscription = new Subscription();

  get canShowLastPage(): boolean {
    return this.totalPages() - 2 > this.currentPage;
  }

  get canShowFirstPage(): boolean {
    return this.currentPage > 3;
  }

  pageChanged({ itemsPerPage, page }: PageChangedEvent) {
    this.pagination.set({
      offset: (page - 1) * itemsPerPage,
      limit: itemsPerPage,
    });
    this.paginationChange.emit(this.pagination());
  }

  ngOnInit() {
    this.subscriptions.add(
      this.itemsPerPageControl.valueChanges.pipe(distinctUntilChanged()).subscribe(perPage => {
        this.pagination.set({
          offset: this.pagination().offset,
          limit: perPage as number,
        });
        this.paginationChange.emit(this.pagination());
      }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}

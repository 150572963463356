import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ITab } from '@smarttypes/core';

@Component({
  selector: 'ui-tabs-page',
  templateUrl: './tabs-page.component.html',
  styleUrls: ['./tabs-page.component.scss'],
  standalone: true,
  imports: [TranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabsPageComponent implements OnInit, AfterViewInit {
  @Input({ required: true }) tabs!: ITab[];
  @Input() activeTab: string | null = null;
  @Input() size: 'medium' | 'large' = 'large';
  @Input() loading = false;
  @Input() allowNoActiveTab = false;
  @Output() tabClick: EventEmitter<ITab> = new EventEmitter();
  @ViewChildren('htmlliElement') activeLiItem?: QueryList<ElementRef<HTMLLIElement>>;

  constructor(private cdr: ChangeDetectorRef) {}

  get slideWidth(): number {
    const active = this.activeLiItem?.toArray()?.find(a => a.nativeElement.classList.contains('active'));
    return active?.nativeElement?.offsetWidth ?? 0;
  }

  get showIndicator(): boolean {
    return !!this.activeTab;
  }

  get leftPosition(): number {
    const liElems = this.activeLiItem?.toArray();
    const active = this.activeLiItem?.toArray()?.findIndex(a => a.nativeElement.classList.contains('active')) ?? -1;

    if (active < 0 || !liElems?.length) {
      return 0;
    }
    return liElems
      .slice(0, active)
      .map(e => {
        return e.nativeElement.offsetWidth ?? 0;
      })
      .reduce((a, c) => a + c, 0);
  }

  count(tab: ITab) {
    return (tab.items ?? 0) + (tab.subItems ?? 0);
  }

  ngOnInit() {
    if (!this.activeTab && !this.allowNoActiveTab) {
      this.activeTab = this.tabs ? this.tabs[0].key : '';
    }
  }

  ngAfterViewInit() {
    if (this.activeTab) {
      this.scrollToActiveElement(this.activeTab);
    }
  }

  onTabClick(tab: ITab) {
    if (this.loading) {
      return;
    }
    this.scrollToActiveElement(tab.key);
    this.tabClick.emit(tab);
    this.cdr.detectChanges();
  }

  private scrollToActiveElement(activeTab: string) {
    const tabElement = this.activeLiItem?.find(
      el => el.nativeElement.getAttribute('data-key') === activeTab,
    )?.nativeElement;

    if (tabElement) {
      setTimeout(() => {
        tabElement.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'center',
        });
      }, 50);
    }
  }
}

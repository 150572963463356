<ui-modal-base [canClose]="canClose" [class.text-center]="cfg?.textCenter" [coverImage]="coverImage"
               [header]="cfg?.header ?? ''" [icon]="cfg?.icon ?? ''" [large]="cfg?.large ?? false" [padding]="false"
               [subtitleHtml]="true" [subtitle]="cfg?.body ?? ''">
  <div content>
    <div *ngIf="warning?.text" class="warning">
      <svg-icon *ngIf="warning?.icon" src="/assets/icons/{{ warning?.icon }}.svg"></svg-icon>
      <p *ngIf="warning?.text">{{ warning?.text ?? '' | translate }}</p>
    </div>
    <div [class.block]="cfg?.blockButtons" class="buttons">
      <ui-button-rectangle
        (click)="onCancelClick()"
        *ngIf="cfg?.cancel"
        [type]="cfg?.btnCancelClass ?? 'tertiary'"
        label="{{ cfg?.cancel ?? '' | translate }}"
        size="l"
      ></ui-button-rectangle>
      <ui-button-rectangle
        (click)="onConfirmClick()"
        *ngIf="cfg?.submit"
        [type]="cfg?.btnClass ?? 'tertiary'"
        label="{{ cfg?.submit ?? '' | translate }}"
        size="l"
      ></ui-button-rectangle>
      <ui-button-rectangle
        (click)="onConfirm2Click()"
        *ngIf="cfg?.submit2"
        [type]="cfg?.btnClass2 ?? 'tertiary'"
        label="{{ cfg?.submit2 ?? '' | translate }}"
        size="l"
      ></ui-button-rectangle>
    </div>
    <small *ngIf="cfg?.hint" class="hint">{{ cfg?.hint }}</small>
  </div>
</ui-modal-base>

import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';
import { LoaderColor, LoaderComponent } from '@ui/common';
import { AngularSvgIconModule } from 'angular-svg-icon';

@Component({
  selector: 'ui-button-circle',
  standalone: true,
  imports: [CommonModule, AngularSvgIconModule, LoaderComponent],
  templateUrl: './button-circle.component.html',
  styleUrls: ['./button-circle.component.scss'],
})
export class ButtonCircleComponent {
  @HostBinding('class') @Input() icon!: string;
  @HostBinding('class.active') @Input() active = false;
  @Input() disabled = false;
  @Input() class = '';
  @Input() iconSmall = false;
  @Input() size: 's' | 'm' | 'l' | 'xs' = 's';
  @Input() color: 'blue' | 'green' | 'danger' | null = null;
  @Input() type:
    | 'tertiary'
    | 'tertiary-v2'
    | 'secondary'
    | 'warning'
    | 'circle'
    | 'quaternary'
    | 'quaternary-light'
    | 'ai'
    | 'white' = 'circle';
  @Input() hover = false;
  @HostBinding('class.pending') @Input() loading = false;

  get iconPath(): string | '' {
    if (this.icon) {
      return `/assets/icons/${this.icon}.svg`;
    }
    return '';
  }

  get iconSize(): string {
    return this.iconSmall ? 'button-icon-small' : '';
  }

  get iconColor(): string {
    return this.color ? `button-color-${this.color}` : '';
  }

  get typeClass(): string {
    return `button-type-${this.type}`;
  }

  get sizeClass(): string {
    return `button-size-${this.size}`;
  }

  get hoverClass(): string {
    return this.hover ? 'hover' : '';
  }

  get loaderColor(): LoaderColor {
    if (this.color === 'blue') {
      return 'white';
    }
    return this.color ?? 'dark';
  }
}

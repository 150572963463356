<div class="step">
  <div class="step-progress">
    <div [ngClass]="{ 'step-bullet-active': progress > 0 }" class="step-bullet"></div>
    <div class="step-line">
      <div [style.width.%]="progress" class="step-indicator"></div>
    </div>
    <div [ngClass]="{ 'step-bullet-active': progress === 100 }" class="step-bullet"></div>
  </div>
  <div class="step-labels">
    @if (checkIn) {
      <div>
        <div class="step-label">
          <span>{{ checkInText | translate }}</span>
        </div>
        <div class="step-title">
          <p>{{ checkIn | localizedDate : 'EEE, d MMM y' : 'UTC' }}</p>
          <!--          @if (guestArea) {-->
          <!--            <p>{{ checkIn | localizedDate : 'EEE, d MMM y' : 'UTC' }}</p>-->
          <!--          } @else {-->
          <!--            <p>{{ checkIn | date : 'EEE, d MMM y' }}</p>-->
          <!--          }-->
        </div>
        @if (checkInFrom) {
          <div class="step-hour">
            {{ 'GA.APARTMENT.AFTER' | translate }} {{ checkInFrom }}
            @if (checkInTo) {
              <span> · {{ 'GA.APARTMENT.BEFORE' | translate }} {{ checkInTo }} </span>
            }
          </div>
        }
      </div>
    }
    @if (checkOut) {
      <div class="text-right">
        <div class="step-label">
          <span>{{ checkOutText | translate }}</span>
        </div>
        <div class="step-title">
          <p>{{ checkOut | localizedDate : 'EEE, d MMM y' : 'UTC' }}</p>
          <!--          @if (guestArea) {-->
          <!--            <p>{{ checkOut | localizedDate : 'EEE, d MMM y' : 'UTC' }}</p>-->
          <!--          } @else {-->
          <!--            <p>{{ checkOut | date : 'EEE, d MMM y' }}</p>-->
          <!--          }-->
        </div>
        @if (checkOutUntil) {
          <div class="step-hour">{{ 'GA.APARTMENT.UNTIL' | translate }} {{ checkOutUntil }}</div>
        }
      </div>
    }
  </div>
</div>

<div [ngxTippy]="dropdownModalTemplate" [tippyName]="modalId()" [tippyProps]="dropdownTippyConfig" class="trigger">
  <ng-content></ng-content>
</div>

<ng-template #dropdownModalTemplate>
  <ul class="dropdown-menu dropdown-menu-inline show">
    @for (item of items(); track item.name; let i = $index) {
      @if (item.ngIf ?? true) {
        <li>
          @if (item.click) {
            <ui-dropdown-item (click)="onItemClick(item.click)" [class]="item.class" [name]="item.name"
                              [icon]="item.icon"></ui-dropdown-item>
          } @else if (item.routerLink) {
            <ui-dropdown-item (click)="onItemClick()" [class]="item.class" [routerLink]="item.routerLink"
                              [name]="item.name" [icon]="item.icon"></ui-dropdown-item>
          }
        </li>
      }
    }
  </ul>
</ng-template>

<div
  #uploader
  (dragleave)="onFileDragLeave()"
  (dragover)="onFileDragOver()"
  (drop)="onFileDragDrop()"
  (fileDropped)="onFileDropped($event)"
  [class.file-added]="file"
  class="uploader"
  shDragNDrop
>
  @if (file) {
    <div class="file-info">
      <svg-icon class="file-ico" src="/assets/icons/icons-color-excel.svg"></svg-icon>
      <span class="file-name">{{ file.name | maxChars : 30 }}</span>
      @if (!pending) {
        <button type="button" class="btn trash" (click)="onFileRemove()">
          <svg-icon src="/assets/icons/icons-trash.svg"></svg-icon>
        </button>
      }
    </div>
  } @else {
    <svg-icon src="/assets/icons/icons-color-excel.svg"></svg-icon>
    <p class="title">{{ 'SH.FRONTEND_LIB.UPLOADER.TITLE' | translate }}</p>
    <span class="description">{{
        'SH.FRONTEND_LIB.UPLOADER.DESCRIPTION' + 'SH.FRONTEND_LIB.UPLOADER.MAX_SIZE' | translate
      }}</span>
    <input #fileDropRef [accept]="formats" type="file" (change)="fileBrowseHandler($any($event.target).files)" />
  }
</div>

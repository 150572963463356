<ui-inline-toast
  *ngIf="errors?.length"
  [labels]="errors"
  [toastInput]="toastTypes.error"
  icon="icons-negative"
></ui-inline-toast>

<ui-import-uploader
  (fileAdded)="onFileAdded($event)"
  (fileRemoved)="onFileRemoved()"
  [file]="file"
  [pending]="uploadInProgress"
></ui-import-uploader>

<ui-button-rectangle
  (click)="onImportClick()"
  [disabled]="disabled"
  [pending]="uploadInProgress"
  label="{{ 'SH.VISITORS_IMPORT.IMPORT_AGAIN' | translate }}"
  size="l"
  type="primary"
></ui-button-rectangle>

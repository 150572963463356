<ui-linked-objects-button
  [counter]="selected().length"
  [ngxTippy]="linkedObjectsModalTemplate"
  [small]="small()"
  [tippyProps]="linkedObjectsTippyConfig"
  tippyName="linked-objects-{{ id() }}"
></ui-linked-objects-button>

<ng-template #linkedObjectsModalTemplate>
  @if (pickerOpen) {
    <ui-modal-base [canClose]="false" class="size-400" header="{{'SH.COPY_TO_APARTMENT' | translate}}">
      <div content>
        <div class="rooms-container">
          <div class="rooms-inner">
            <div [formGroup]="form" class="select-all">
              <ui-checkbox
                [spaceAround]="true"
                [value]="false"
                formControlName="selectAll"
                text="{{ 'SH.ALL_APARTMENTS' | translate }}"
              ></ui-checkbox>
            </div>
            @if (objects().length > 0) {
              <div class="rooms-list" [formGroup]="getRoomsControl">
                @for (room of objects(); track room.value) {
                  <ui-checkbox
                    [formControlName]="room.value"
                    [lines]="1"
                    [spaceAround]="true"
                    [text]="room.label"
                    [value]="form.get('rooms')?.get(room.value)?.value ?? false"
                  ></ui-checkbox>
                }
              </div>
            }
          </div>
        </div>

        <div class="button-area">
          <ui-button-rectangle
            (click)="onCancel()"
            [disabled]="pending()"
            label="{{ 'SH.CANCEL' | translate }}"
            size="l"
            type="tertiary"
          ></ui-button-rectangle>
          <ui-button-rectangle
            (click)="onSave()"
            [disabled]="pending()"
            [pending]="pending()"
            label="Save"
            size="l"
            type="primary"
          ></ui-button-rectangle>
        </div>
      </div>
    </ui-modal-base>
  }
</ng-template>

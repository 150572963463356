@if (icon) {
  <svg-icon [src]="'/assets/icons/' + icon + '.svg'"></svg-icon>
}
@if (pending) {
  <ui-loader size="m" color="dark" [showSpinner]="true"></ui-loader>
}
<span class="toast-message" [innerHTML]="message"></span>
@if(buttonText) {
  <span class="toast-button" (click)="onClick()">{{ buttonText }}</span>
}

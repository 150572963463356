import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppModules } from '@smarttypes/core';

import { environment } from '../../environments/environment';
import { AuthGuard } from '../core/guards/auth-guard';
import { CompanyModuleGuard } from '../core/guards/company-module-guard';
import { ExpiredAccountGuard } from '../core/guards/expired-account-guard';
import { MobileGuard } from '../core/guards/mobile-guard';
import { MobileNotSupportedGuard } from '../core/guards/mobile-not-supported-guard';
import { PreliminaryTrialGuard } from '../core/guards/preliminary-trial-guard';
import { ServiceAccountGuard } from '../core/guards/service-account-guard';
import { SwitchCompanyGuard } from '../core/guards/switch-company-guard';
import { DashboardViewComponent } from './dashboard-view/dashboard-view.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard, SwitchCompanyGuard],
    component: DashboardViewComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: environment.homepage,
      },
      {
        path: 'livechat',
        canActivate: [CompanyModuleGuard, MobileGuard, ExpiredAccountGuard, SwitchCompanyGuard],
        data: {
          module: [AppModules.unifiedInbox, AppModules.smartAssistant],
          whiteTopBarMode: true,
        },
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
        loadChildren: () => import('./livechat/livechat.module').then(m => m.LivechatModule),
      },
      {
        path: 'smart-ai',
        canActivate: [ExpiredAccountGuard, MobileGuard],
        // data: {
        //   module: 'smart-ai',
        // },
        loadChildren: () => import('./smart-ai-view/smart-ai-view.module').then(m => m.SmartAiViewModule),
      },
      {
        path: 'ai-assistant',
        canActivate: [CompanyModuleGuard, MobileGuard],
        data: {
          module: [AppModules.smartAssistantV3, AppModules.smartAssistantV3Settings],
        },
        loadChildren: () => import('./ai-assistant-view/ai-assistant-view.module').then(m => m.AiAssistantViewModule),
      },
      {
        path: 'guest-area',
        canActivate: [CompanyModuleGuard, MobileGuard],
        data: {
          module: 'visitors-area',
        },
        loadChildren: () => import('./guest-area-view/guest-area-view.module').then(m => m.GuestAreaViewModule),
      },
      {
        path: 'guest-area-history-view',
        canActivate: [CompanyModuleGuard, MobileGuard],
        data: {
          module: 'visitors-area',
        },
        loadChildren: () =>
          import('./guest-area-history-view/guest-area-history-view.module').then(m => m.GuestAreaHistoryViewModule),
      },
      {
        path: 'visitors-place',
        canActivate: [CompanyModuleGuard, MobileGuard],
        data: {
          module: AppModules.visitorsPlaceModule,
        },
        loadChildren: () =>
          import('./visitors-place-view/visitors-place-view.module').then(m => m.VisitorsPlaceViewModule),
      },
      {
        path: 'automation-settings',
        canActivate: [CompanyModuleGuard, MobileGuard],
        data: {
          module: [AppModules.smartAutomation, AppModules.automationSettings],
        },
        loadChildren: () => import('./automation-view/automation-view.module').then(m => m.AutomationViewModule),
      },
      {
        path: 'guests',
        canActivate: [CompanyModuleGuard, MobileGuard, ExpiredAccountGuard],
        data: {
          module: 'guests',
        },
        loadChildren: () => import('./guests-view/guests-view.module').then(m => m.GuestsViewModule),
      },
      {
        path: 'owners',
        canActivate: [CompanyModuleGuard, MobileGuard, ExpiredAccountGuard],
        data: {
          module: 'owners',
        },
        loadChildren: () => import('./owners-view/owners-view.module').then(m => m.OwnersViewModule),
      },
      {
        path: 'requests',
        canActivate: [CompanyModuleGuard, MobileGuard, ExpiredAccountGuard],
        data: {
          module: 'requests',
        },
        loadChildren: () => import('./requests-view/requests-view.module').then(m => m.RequestsViewModule),
      },
      {
        path: 'tasks',
        canActivate: [CompanyModuleGuard, MobileGuard],
        data: {
          module: 'tasks',
        },
        loadChildren: () => import('./requests-view/requests-view.module').then(m => m.RequestsViewModule),
      },
      {
        path: 'mass-messaging',
        canActivate: [CompanyModuleGuard, MobileGuard, ExpiredAccountGuard],
        data: {
          module: 'massMessaging',
        },
        loadChildren: () => import('./mass-messaging-view/mass-messaging-view.module').then(m => m.MassMessagingModule),
      },
      {
        canActivate: [MobileGuard, ExpiredAccountGuard],
        path: 'settings',
        data: {
          module: 'settings',
        },
        loadChildren: () => import('./settings-view/settings-view.module').then(m => m.SettingsViewModule),
      },
      {
        canActivate: [MobileGuard, ExpiredAccountGuard, ServiceAccountGuard],
        path: 'account',
        loadChildren: () => import('./account-view/account-view.module').then(m => m.AccountViewModule),
      },
      {
        path: 'media-library',
        canActivate: [CompanyModuleGuard, MobileGuard, ExpiredAccountGuard],
        data: {
          module: 'media-library',
        },
        loadChildren: () => import('./library-view/library-view.module').then(m => m.LibraryViewModule),
      },
      {
        path: 'qr-materials',
        canActivate: [CompanyModuleGuard, MobileGuard, ExpiredAccountGuard, PreliminaryTrialGuard],
        data: {
          module: 'qr-materials',
        },
        loadChildren: () => import('./materials-view/materials-view.module').then(m => m.MaterialsViewModule),
      },
      {
        path: 'users',
        canActivate: [CompanyModuleGuard, MobileGuard, ExpiredAccountGuard],
        data: {
          module: 'users',
        },
        loadChildren: () => import('./users-view/users-view.module').then(m => m.UsersViewModule),
      },
      {
        path: 'rooms',
        canActivate: [CompanyModuleGuard, MobileGuard, ExpiredAccountGuard],
        data: {
          module: [AppModules.rooms, AppModules.apartments],
        },
        loadChildren: () => import('./rooms-view/rooms-view.module').then(m => m.RoomsViewModule),
      },
      {
        path: 'analytics',
        canActivate: [CompanyModuleGuard, MobileGuard, PreliminaryTrialGuard],
        data: {
          module: 'analytics',
        },
        loadChildren: () => import('./analytics-view/analytics-view.module').then(m => m.AnalyticsViewModule),
      },
      {
        path: 'buildings',
        canActivate: [CompanyModuleGuard, MobileGuard, ExpiredAccountGuard],
        data: {
          module: 'buildings',
        },
        loadChildren: () => import('./buildings-view/buildings-view.module').then(m => m.BuildingsViewModule),
      },
      {
        path: 'payments',
        canActivate: [MobileGuard, ExpiredAccountGuard],
        data: {
          module: 'payments',
        },
        loadChildren: () => import('./payments-view/payments-view.module').then(m => m.PaymentsViewModule),
      },
      {
        path: 'registration-form',
        canActivate: [MobileGuard, CompanyModuleGuard, ExpiredAccountGuard],
        data: {
          module: 'registrationForm',
        },
        loadChildren: () =>
          import('./registration-form-view/registration-form-view.module').then(m => m.RegistrationFormViewModule),
      },
      {
        path: 'start',
        loadChildren: () => import('./empty-view/empty-view.module').then(m => m.EmptyViewModule),
      },
      {
        path: 'mobile-not-support',
        canActivate: [MobileNotSupportedGuard],
        loadChildren: () =>
          import('./mobile-not-support-view/mobile-not-support-view.module').then(m => m.MobileNotSupportViewModule),
        data: {
          whiteTopBarMode: true,
        },
      },
      {
        path: 'integrations',
        canActivate: [MobileGuard, CompanyModuleGuard, ExpiredAccountGuard],
        data: {
          module: 'integrations',
        },
        loadChildren: () => import('./integrations-view/integrations-view.module').then(m => m.IntegrationsViewModule),
      },
      {
        path: 'integrations-admin',
        canActivate: [MobileGuard, CompanyModuleGuard, ExpiredAccountGuard],
        data: {
          module: 'integrations',
        },
        loadChildren: () =>
          import('./integrations-admin-view/integrations-admin-view.module').then(m => m.IntegrationsAdminViewModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ViewsRoutingModule {}

import { CommonModule } from '@angular/common';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { MissingTranslationHandler, TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule, SvgIconComponent, SvgLoader } from 'angular-svg-icon';
import { FormControlDirective } from 'angular-v2-directives';
import { CustomMissingTranslationHandler, SvgBrowserLoader } from 'angular-v2-utils';
import { NgxTippyModule } from 'ngx-tippy-wrapper';

import { CommunicationLimitTipComponent } from './components/communication-limit-tip/communication-limit-tip.component';
import { FormComponent } from './components/form.component';
import { SelectComponent } from './components/select/select.component';

function provideSvgLoader(http: HttpClient) {
  return new SvgBrowserLoader(http);
}

@NgModule({
  declarations: [SelectComponent, FormComponent, CommunicationLimitTipComponent],
  exports: [
    NgxTippyModule,
    SvgIconComponent,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    SelectComponent,
    FormComponent,
  ],
  imports: [
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    TranslateModule,
    AngularSvgIconModule.forRoot({
      loader: {
        provide: SvgLoader,
        useFactory: provideSvgLoader,
        deps: [HttpClient],
      },
    }),
    CommonModule,
    NgxTippyModule,
    FormControlDirective,
  ],
  providers: [
    {
      provide: MissingTranslationHandler,
      useClass: CustomMissingTranslationHandler,
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class FrontendUiModule {}

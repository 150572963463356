import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { ApplicationLanguageService } from '../core/services/application-language.service';

@Injectable({
  providedIn: 'root',
})
export class I18nService {
  readonly httpClient = inject(HttpClient);
  readonly appLangService = inject(ApplicationLanguageService);
  readonly translateService = inject(TranslateService);

  constructor() {}

  getTranslations(lang?: string, company?: string): Observable<Record<string, string>> {
    return this.#fetchTranslations(lang, company);
  }

  getGuestAreaTranslations(lang: string): Observable<Record<string, string>> {
    return this.#fetchTranslations(lang, undefined, 'ga');
  }

  #fetchTranslations(lang?: string, companyType?: string, prefix = 'sh'): Observable<Record<string, string>> {
    const language = lang ?? this.appLangService.getCurrentLanguage();
    let params: HttpParams = new HttpParams();

    if (environment?.i18n?.force) {
      params = params.append('force', true);
    }

    if (companyType) {
      params = params.append('type', companyType);
    }

    return this.httpClient.get<Record<string, string>>(this.#translationUrl(prefix, language), { params });
  }

  #translationUrl(prefix: string, lang: string): string {
    if (prefix) {
      return `${environment.apiUrl}translate/app/${prefix}/${lang}`;
    }
    return environment?.i18n?.url ?? `${environment.apiUrl}translate/app/sh/${lang}`;
  }
}
